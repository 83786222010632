<template>
  <div class="myPdfOnlinePreviewBox">
    <pdf
      id="pdfIframe"
      :src="pdfSrc"
      class="pdfOnlinePreview"
    ></pdf>
  </div>
</template>

<script>
  import pdf from 'vue-pdf'

  export default {
    name: "index",
    components: { pdf },
    data(){
      return{
        pdfUrl:null,
        pdfSrc:null
      }
    },
    mounted() {
      this.init()
    },
    methods:{
      init(){
        let windowHeight = window.innerHeight
        let pdfIframe = document.getElementById("pdfIframe")
        pdfIframe.style.height = (windowHeight-120)+'px'

        let fileName = window.location.href.substring(window.location.href.lastIndexOf("=")+1)
        this.pdfUrl = window.location.origin + "/admin/sys-file/minio/ipixel-fs/"+fileName

        this.pdfSrc = pdf.createLoadingTask({
          url: this.pdfUrl,
          cMapUrl: 'https://unpkg.com/pdfjs-dist@2.9.359/cmaps/',
          cMapPacked: true
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .myPdfOnlinePreviewBox{
    display: inline-block;
    margin: 110px 10% 0;
    text-align: center;
  }
</style>
